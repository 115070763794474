import React from "react";
import { Linkedin } from "react-feather";

export default function LinkedIn() {
  return (
    <a
      href="https://www.linkedin.com/in/leo-wibbels-53b596193/"
      target="_blank"
      rel="noopener noreferrer"
      className="social-button"
    >
      <Linkedin size={24} className="social-icon" />
      <span className="label">LinkedIn</span>
    </a>
  );
}
