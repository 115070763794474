import React from "react";
import { Mail } from "react-feather";

export default function MailButton() {
  return (
    <a
      href="mailto:wibbels.leoo@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
      className="social-button"
    >
      <Mail size={24} className="social-icon" />
      <span className="label">Email</span>
    </a>
  );
}
